import React from "react"
import clientConfig from "../../client-config"
import BasePortableText from "@sanity/block-content-to-react"
import OptimizedImage from "./optimizedImage"

/**
 * Portable Text Compoennt
 * This component is used to parse sanity block Contents
 * to valid react components, if you want to create custom handlers
 * you can use the serializers object
 *
 * @param {Object} props
 */
const PortableText = ({ blocks }) => (
  <BasePortableText
    blocks={blocks}
    serializers={{ types: { image: OptimizedImage } }}
    {...clientConfig.sanity}
  />
)

export default PortableText
