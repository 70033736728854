import React from "react"
import { graphql } from "gatsby"
import { format } from "date-fns"
import OptimizedImage from "gatsby-image/withIEPolyfill"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import ContactForm from "../components/contactForm"
import PortableText from "../components/portableText"
import Sidebar from "../components/sidebar"

/**
 * GraphQL Query to retrieve template data
 * The query params are part of the component pageContext,
 * this context is coming from gatsby-node.js file
 *
 * @param {String} id
 * @return {Object}
 */
export const PostQuery = graphql`
  query PostQuery($id: String!) {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/hero.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      title
      keywords
      slug {
        current
      }
      mainImage {
        asset {
          _id
          url
          fluid(maxHeight: 360) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
      categories {
        title
      }
      author {
        name
      }
      excerpt
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`

/**
 * Post Template Component
 * This component is used to generate the pages related to a Single Post.
 * These pages can be accessible using the path `/{slug}/`
 *
 * @param {Object} props
 */
const PostTemplate = ({ data }) => {
  const { post, hero, site } = data

  return (
    <Layout isSecondary={true}>
      <SEO
        title={post.title}
        description={post.excerpt}
        keywords={post.keywords.join(",")}
      />

      <Hero
        type="simple"
        tagline="Most successful Law Firm"
        title={post.title}
        background={hero.sharp.fluid}
      />

      <section className="single-post">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-9">
              <div className="single-post__container">
                <div className="single-post__image">
                  <div className="single-post__image-inner">
                    <OptimizedImage
                      fluid={post.mainImage.asset.fluid}
                      alt={post.title}
                      fadeIn={true}
                      objectFit="contain"
                      objectPosition="50% 50%"
                    />
                  </div>
                </div>
                <div className="single-post__content">
                  <div className="single-post__date">
                    <span>{format(post.publishedAt, "DD")}</span>
                    {format(post.publishedAt, "MMM")}
                  </div>
                  <div className="single-post__meta">
                    {post.author && <p>{post.author.name}</p>}
                    {post.categories &&
                      post.categories.map((category, index) => (
                        <p key={index}>{category.title}</p>
                      ))}
                  </div>
                  {/* <h1 className="single-post__title">{post.title}</h1> */}

                  <div className="single-post__text">
                    {post._rawBody && <PortableText blocks={post._rawBody} />}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <ContactForm
        email={site.siteMetadata.email}
        phone={site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default PostTemplate
