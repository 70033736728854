import React from "react"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import clientConfig from "../../client-config"

/**
 * OptimizedImage Component
 * This component is used to generate Optimized image versions
 * This component is using the GetFluidGatsbyImage function
 * based on asset ID value
 *
 * @param {Object} props
 */
const OptimizedImage = ({ node, maxWidth = 720 }) => {
  if (!node || !node.asset || !node.asset._id) {
    return null
  }
  const fluidProps = getFluidGatsbyImage(
    node.asset._id,
    { maxWidth: maxWidth },
    clientConfig.sanity
  )
  return (
    <figure>
      <Img fluid={fluidProps} alt={node.alt || ""} fadeIn />
      {node.caption && (
        <figcaption className="text-center text-sm">{node.caption}</figcaption>
      )}
    </figure>
  )
}

export default OptimizedImage
